/*
 * ⚠ This file is autogenerated ⚠
 */

import { Colors } from '../common/colors';

export const colors: Colors = {
  actionlegacyprimarygreenText: '#ffffff',
  actionlegacyprimarygreenPressed: '#4abb10',
  actionlegacyprimarygreenDisabledlight: '#e6e8e9',
  actionlegacyprimarygreenHover: '#5ec031',
  actionlegacyprimarygreenDefault: '#4abb10',
  opacityLighten4: 'rgba(255, 255, 255, 0.5)',
  modalOverlay: 'rgba(0, 0, 0, 0.7)',
  lighten4: 'rgba(255, 255, 255, 0.5)',
  overlayHover: 'rgba(219, 223, 255, 0.14)',
  actionattentionPressed: '#d43b3b',
  actionattentionDisabledlight: '#f2f2f8',
  actionattentionHover: '#ff5c5c',
  actionattentionDefault: '#d43b3b',
  actionvalidationPressed: '#00866f',
  actionvalidationDisabledlight: '#f2f2f8',
  actionvalidationHover: '#00a980',
  actionvalidationDefault: '#00866f',
  tagprimaryText: '#071a26',
  tagprimaryPressed: '#6db6ff',
  tagprimaryDisabledlight: '#e6e8e9',
  tagprimaryHover: '#a3d1ff',
  tagprimaryDefault: '#c8e4ff',
  actioninformationText: '#ffffff',
  actioninformationPressed: '#023e76',
  actioninformationDisabledlight: '#e6e8e9',
  actioninformationHover: '#024685',
  actioninformationDefault: '#024e94',
  actionwarningText: '#ffffff',
  actionwarningPressed: '#974900',
  actionwarningDisabledlight: '#e6e8e9',
  actionwarningHover: '#aa5200',
  actionwarningDefault: '#bd5b00',
  actionsuccessText: '#ffffff',
  actionsuccessPressed: '#026236',
  actionsuccessDisabledlight: '#e6e8e9',
  actionsuccessHover: '#036e3d',
  actionsuccessDefault: '#037a44',
  actioncriticalText: '#ffffff',
  actioncriticalPressed: '#b63124',
  actioncriticalDisabledlight: '#e6e8e9',
  actioncriticalHover: '#cc3728',
  actioncriticalDefault: '#e33d2d',
  actionlegacysecondaryText: '#071a26',
  actionlegacysecondaryPressed: '#c2bfba',
  actionlegacysecondaryDisabledlight: '#e6e8e9',
  actionlegacysecondaryHover: '#dad7d1',
  actionlegacysecondaryDefault: '#f2efe8',
  actionlegacyprimaryText: '#071a26',
  actionlegacyprimaryPressed: '#cc9722',
  actionlegacyprimaryDisabledlight: '#e6e8e9',
  actionlegacyprimaryHover: '#e5aa27',
  actionlegacyprimaryDefault: '#ffbc2b',
  actionsecondaryText: '#071a26',
  actionsecondaryPressed: '#b5babe',
  actionsecondaryDisabledlight: '#e6e8e9',
  actionsecondaryHover: '#cdd1d4',
  actionsecondaryDefault: '#e6e8e9',
  actionprimaryText: '#ffffff',
  actionprimaryPressed: '#025da8',
  actionprimaryDisabledlight: '#e6e8e9',
  actionprimaryHover: '#0268bd',
  actionprimaryDefault: '#0374da',
  focusDefault: '#68ace9',
  strokeWarninglight: '#fcf7f2',
  strokeWarninglarge: '#ffcc80',
  strokeAttentionlarge: '#ff5c5c',
  strokeAttention: '#ff5c5c',
  strokeValidationlarge: '#00a980',
  strokePrimarysmall: '#0a70a3',
  strokeFocus: '#68ace9',
  strokeInformationlight: '#f2f6fa',
  strokeInformationmedium: '#ccdcea',
  strokeInformation: '#6795bf',
  strokeInformationdark: '#024e94',
  strokeWarningmedium: '#f2decc',
  strokeWarning: '#d79d66',
  strokeWarningdark: '#bd5b00',
  strokeSuccesslight: '#f2f8f6',
  strokeSuccessmedium: '#cde4da',
  strokeSuccess: '#68af8f',
  strokeSuccessdark: '#037a44',
  strokeCriticallight: '#fef5f5',
  strokeCriticalmedium: '#f9d8d5',
  strokeCritical: '#ee8b81',
  strokeCriticaldark: '#e33d2d',
  strokeDiscountlight: '#ffeded',
  strokeDiscountmedium: '#ffb2b2',
  strokeDiscount: '#ff5c5c',
  strokeDiscountdark: '#d43b3b',
  strokePrimary: '#0374da',
  strokePrimarydark: '#0268bd',
  strokeNone: 'rgba(255, 255, 255, 0)',
  strokeLightsubtle: '#f7f5f1',
  strokeLightneutral: '#f2efe8',
  strokeDarkneutral: '#9ca3a8',
  strokeDisabledlight: '#cdd1d4',
  strokeLight: '#f3f4f4',
  strokeWhite: '#ffffff',
  strokeDark: '#14142b',
  interactiveDisabledlight: '#e6e8e9',
  interactiveSelected: '#071a26',
  interactiveHover: '#394851',
  interactiveDefault: '#6a767d',
  iconGooglereview: '#f4b400',
  iconMove: '#00866f',
  iconRemovetag: '#8447ee',
  iconAddtag: '#12aaf6',
  iconPrimarysmall: '#0a70a3',
  iconInformationlarge: '#ffcc80',
  iconInformationsmall: '#e17a00',
  iconValidationlarge: '#00a980',
  iconValidationsmall: '#00866f',
  iconAttentionlarge: '#ff5c5c',
  iconAttentionsmall: '#d43b3b',
  iconTrustpilot5star: '#00b67a',
  iconTrustpilot4star: '#73cf11',
  iconTrustpilot3star: '#ffce00',
  iconTrustpilot2star: '#ff8622',
  iconTrustpilot1star: '#ff3722',
  iconTrustpilot0star: '#d9dbe9',
  iconTripadvisor: '#00aa6c',
  iconInformation: '#6795bf',
  iconInformationdark: '#024e94',
  iconWarning: '#d79d66',
  iconWarningdark: '#bd5b00',
  iconSuccess: '#68af8f',
  iconSuccessdark: '#037a44',
  iconCritical: '#ee8b81',
  iconCriticaldark: '#e33d2d',
  iconDiscount: '#ff5c5c',
  iconDiscountdark: '#d43b3b',
  iconPrimary: '#0374da',
  iconPrimarydark: '#0268bd',
  iconPressed: '#071a26',
  iconHovered: '#061722',
  iconDimmedheavy: '#394851',
  iconDimmedmedium: '#6a767d',
  iconDisabled: '#cdd1d4',
  iconDisabledlight: '#e6e8e9',
  iconLight: '#ffffff',
  iconDefault: '#071a26',
  textInformationlarge: '#ffcc80',
  textInformationsmall: '#e17a00',
  textValidationlarge: '#00a980',
  textValidationsmall: '#00866f',
  textAttentionlarge: '#ff5c5c',
  textAttentionsmall: '#d43b3b',
  textPrimarysmall: '#0a70a3',
  textInformation: '#6795bf',
  textInformationdark: '#024e94',
  textWarning: '#d79d66',
  textWarningdark: '#bd5b00',
  textSuccess: '#68af8f',
  textSuccessdark: '#037a44',
  textCritical: '#ee8b81',
  textCriticaldark: '#e33d2d',
  textDiscount: '#ff5c5c',
  textDiscountdark: '#d43b3b',
  textLegacydark: '#e5aa27',
  textLegacy: '#ffbc2b',
  textPrimary: '#0374da',
  textPrimarydark: '#0268bd',
  textDimmedheavy: '#394851',
  textDimmedmedium: '#6a767d',
  textDisabled: '#9ca3a8',
  textDisabledlight: '#e6e8e9',
  textLight: '#ffffff',
  textDefault: '#071a26',
  backgroundValidationlight: '#e4f5ef',
  backgroundValidationmedium: '#88e3d3',
  backgroundValidation: '#00a980',
  backgroundValidationdark: '#00866f',
  backgroundAttentionlight: '#ffeded',
  backgroundAttentionmedium: '#ffb2b2',
  backgroundAttention: '#ff5c5c',
  backgroundAttentiondark: '#d43b3b',
  backgroundMove: '#e4f5ef',
  backgroundRemovetag: '#ebe5f9',
  backgroundAddtag: '#e4f1ff',
  backgroundSteel: '#000000',
  backgroundInformationlight: '#f2f6fa',
  backgroundInformationmedium: '#ccdcea',
  backgroundInformation: '#6795bf',
  backgroundInformationdark: '#024e94',
  backgroundWarninglight: '#fcf7f2',
  backgroundWarningmedium: '#f2decc',
  backgroundWarning: '#d79d66',
  backgroundWarningdark: '#bd5b00',
  backgroundSuccesslight: '#f2f8f6',
  backgroundSuccessmedium: '#cde4da',
  backgroundSuccess: '#68af8f',
  backgroundSuccessdark: '#037a44',
  backgroundCriticallight: '#fef5f5',
  backgroundCriticalmedium: '#f9d8d5',
  backgroundCritical: '#ee8b81',
  backgroundCriticaldark: '#e33d2d',
  backgroundDiscountlight: '#ffeded',
  backgroundDiscountmedium: '#ffb2b2',
  backgroundDiscount: '#ff5c5c',
  backgroundDiscountdark: '#d43b3b',
  backgroundPrimarylight: '#f2f8fd',
  backgroundPrimarymedium: '#cce3f6',
  backgroundPrimary: '#0374da',
  backgroundLight: '#fcfcfa',
  backgroundLightsubtle: '#f7f5f1',
  backgroundLightneutral: '#f2efe8',
  backgroundDarkneutral: '#9ca3a8',
  backgroundDarksubtle: '#6a767d',
  backgroundDark: '#071a26',
  backgroundNone: 'rgba(255, 255, 255, 0)',
  backgroundWhite: '#ffffff',
  backgroundBlack: '#000000',
  baseSuccess: '#037a44',
  baseCritical: '#e33d2d',
  baseWarning: '#bd5b00',
  baseInformation: '#024e94',
  baseValidation: '#00a980',
  baseAttention: '#ff5c5c',
  baseInteraction: '#071a26',
  baseLight: '#f2efe8',
  baseDark: '#071a26',
  baseSecondary: '#cde3f8',
  basePrimary: '#0374da',
  baseYellowlight: '#fdeac4',
  baseYellow: '#ffbc2b',
};
